import Reactor from './Reactor.js';

class GeneModel {
  constructor(globalApp, genomeBuildHelper) {

    this.globalApp                 = globalApp;
    this.genomeBuildHelper         = genomeBuildHelper;
    this.limitGenes                = 100;

    this.NCBI_GENE_SEARCH_URL      = "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=gene&usehistory=y&retmode=json";
    this.NCBI_GENE_SUMMARY_URL     = "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=gene&usehistory=y&retmode=json";


    this.NCBI_PUBMED_SEARCH_URL    = "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&usehistory=y&retmode=json";
    this.NCBI_PUBMED_SUMMARY_URL   = "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&usehistory=y&retmode=json";

    this.ENSEMBL_GENE_URL          = "https://rest.ensembl.org/xrefs/symbol/homo_sapiens/GENESYMBOL?content-type=application/json"
    this.ENSEMBL_LOOKUP_BY_ID      = "https://rest.ensembl.org/xrefs/id/ENSEMBL-GENE-ID?content-type=application/json"
    this.OMIM_URL                  = "https://api.omim.org/api/";
    this.warnedMissingOMIMApiKey   = false;

    this.HPO_URL                   = "https://hpo.jax.org/api/hpo/gene/"

    this.linkTemplates = {
        omim:      { display: 'OMIM',      url: 'https://www.omim.org/search/?search=GENESYMBOL'},
        opentargets:{ display: 'Open Targets',      url: 'https://platform.opentargets.org/target/ENSEMBL-GENE-ID/associations/'},
        humanmine: { display: 'HumanMine', url: 'http://www.humanmine.org/humanmine/keywordSearchResults.do?searchTerm=+GENESYMBOL&searchSubmit=GO'},
        ncbi:      { display: 'NCBI',      url: 'https://www.ncbi.nlm.nih.gov/gene/GENEUID'},
        pubmed:    { display: 'PubMed',    url: 'https://pubmed.ncbi.nlm.nih.gov/?from_uid=GENEUID&linkname=gene_pubmed'},
        decipher:  { display: 'DECIPHER',  url: 'https://decipher.sanger.ac.uk/search?q=GENESYMBOL'},
        marrvel:   { display: 'MARRVEL',   url: 'http://marrvel.org/search/gene/GENESYMBOL'},
        genecards: { display: 'GeneCards', url: 'https://www.genecards.org/cgi-bin/carddisp.pl?gene=GENESYMBOL'},
        uniprot:   { display: 'UniProt',   url: 'http://www.uniprot.org/uniprot/?query=gene:GENESYMBOL AND organism:"Homo sapiens (Human) [9606]"'},
        gtex:      { display: 'GTex',      url: 'https://www.gtexportal.org/home/gene/GENESYMBOL'},
        humanproteinatlas:
                    { display: 'Human Protein Atlas', url: 'https://www.proteinatlas.org/search/gene_name:GENESYMBOL'},
        ucsc:       { display: 'UCSC Browser',        url: 'http://genome.ucsc.edu/cgi-bin/hgTracks?db=GENOMEBUILD-ALIAS-UCSC&position=GENECOORD'},
    }

    this.variantLinkTemplates = {
        gnomad:    { display: 'gnomAD',       url: 'http://gnomad.broadinstitute.org/variant/VARIANTCOORD-GNOMAD'},
        varsome:   { display: 'VarSome',      url: 'https://varsome.com/variant/GENOMEBUILD-ALIAS-UCSC/VARIANTCOORD-VARSOME'},
        dbsnp:     { display: 'dbSNP',        url: 'http://www.ncbi.nlm.nih.gov/snp/VARIANT-RSID'},
        ucsc:      { display: 'UCSC Browser', url: 'http://genome.ucsc.edu/cgi-bin/hgTracks?db=GENOMEBUILD-ALIAS-UCSC&position=VARIANTCOORD-UCSC'},
        clinvar:   { display: 'ClinVar',      url: 'https://www.ncbi.nlm.nih.gov/clinvar/variation/VARIANT-CLINVAR-UID/'}
    }

    this.geneSources = ['gencode', 'refseq'];

    this.geneSource = 'gencode';
    this.refseqOnly = {};
    this.gencodeOnly = {};

    this.geneNames = [];
    this.sortedGeneNames = [];
    this.candidateGenes = null;


    this.geneNCBISummaries = {};
    this.geneOMIMEntries = {};
    this.genePubMedEntries = {};
    this.geneClinvarPhenotypes = {};
    this.genePhenotypes = {};
    this.geneObjects = {};
    this.geneToLatestTranscript = {};
    this.geneToEnsemblId = {};
    this.geneToHPOTerms = {};
    this.geneToSpliceJunctionRecords = {}
    this.geneToSpliceJunctionObjects = {}
    this.geneToSpliceJunctionSummary = {}


    this.allKnownGenes = [];
    this.allKnownGeneNames = {};

    this.transcriptCodingRegions = {};

    this.geneRegionBuffer = 1000;

    this.pendingNCBIRequests = {};

    this.rankedGenes = {};

    this.genesAssociatedWithSource = {};

    this.reactor = new Reactor();
    this.reactor.registerEvent("alertIssued")


  }

  addEventListener(eventName, callback) {
    this.reactor.addEventListener(eventName, callback)
  }

  dispatchEvent(eventName, eventArgs) {
    this.reactor.dispatchEvent(eventName, eventArgs)
  }


  getGenePanelNames() {
    let self = this;
    let siteName = import.meta.env.ENV_SITE_NAME;
    let filteredGenePanelNames = Object.keys(this.genePanels).filter(function(name) {
      let gp = self.genePanels[name];
      if (gp.sites == null || (siteName != null && siteName.length > 0 && gp.sites.indexOf(siteName) >= 0)) {
        return true;
      } else {
        return false;
      }
    })
    return filteredGenePanelNames;
  }

  getGenePanelShortName(name) {
    if (this.genePanels[name]) {
      return this.genePanels[name].shortName
    } else {
      return null;
    }
  }

  getGenePanelGenes(name) {
    if (this.genePanels[name]) {
      return this.genePanels[name].genes;
    } else {
      return null;
    }
  }

  setCandidateGenes(genes) {
    let self = this;
    self.candidateGenes = {};
    genes.forEach(function(gene) {
      self.candidateGenes[gene] = true;
    })
  }

  getCandidateGenes() {
    let self = this;
    return Object.keys(self.candidateGenes);
  }

  isCandidateGene(theGeneName) {
    let self = this;
    if (self.candidateGenes != null) {
      return self.candidateGenes[theGeneName];
    } else {
      return true;
    }
  }


  getGenePhenotypeHits(geneName) {
    if (this.genePhenotypeHits) {
      return this.genePhenotypeHits[geneName];
    } else {
      return null;
    }
  }


  setGenePhenotypeHitsFromClin(genesReport) {
    let self = this;
    if (genesReport) {
      this.genePhenotypeHits = {};
      genesReport.forEach(function(geneEntry) {
        var searchTerms = self.genePhenotypeHits[geneEntry.name];
        if (searchTerms == null) {
          searchTerms = {};
          self.genePhenotypeHits[geneEntry.name] = searchTerms;
        }
        if (geneEntry.searchTermsGtr && geneEntry.searchTermsGtr.length > 0) {
          geneEntry.searchTermsGtr.forEach(function(searchTermObject) {
            var searchTerm = searchTermObject.searchTerm.split(" ").join("_");
            var ranks = searchTerms[searchTerm];
            if (ranks == null) {
              ranks = [];
              searchTerms[searchTerm] = ranks;
            }
            ranks.push( {'rank': searchTermObject.rank, 'source': 'GTR'});
          })
        }
        if (geneEntry.searchTermsPhenolyzer && geneEntry.searchTermsPhenolyzer.length > 0) {
          geneEntry.searchTermsPhenolyzer.forEach(function(searchTermObject) {
            var searchTerm = searchTermObject.searchTerm.split(" ").join("_");
            var ranks = searchTerms[searchTerm];
            if (ranks == null) {
              ranks = [];
              searchTerms[searchTerm] = ranks;
            }
            ranks.push( {'rank': searchTermObject.rank, 'source': 'Phenolyzer'});
          })
        }
        if (geneEntry.searchTermHpo && geneEntry.searchTermHpo.length > 0) {
          geneEntry.searchTermHpo.forEach(function(searchTermObject) {
            var searchTerm = searchTermObject.searchTerm.split(" ").join("_");
            var ranks = searchTerms[searchTerm];
            if (ranks == null) {
              ranks = [];
              searchTerms[searchTerm] = ranks;
            }
            ranks.push( { 'hpoPhenotype': searchTermObject.hpoPhenotype, 'source': 'HPO'});
          })
        }

      })

    }
  }

  setGenePhenotypeHitsFromPhenolyzer(phenotypeTerm, phenotypeGenes) {
    let self = this;

    if (phenotypeGenes && phenotypeGenes.length > 0) {
      var searchTerm = phenotypeTerm.split(" ").join("_");
      phenotypeGenes.forEach(function(phenotypeGene) {
        var searchTerms = self.genePhenotypeHits[phenotypeGene.geneName];
        if (searchTerms == null) {
          searchTerms = {};
          self.genePhenotypeHits[phenotypeGene.geneName] = searchTerms;
        }
        var ranks = searchTerms[searchTerm];
        if (ranks == null) {
          ranks = [];
          searchTerms[searchTerm] = ranks;
        }
        let matchingRanks = ranks.filter(function(entry) {
          return entry.source == 'Phenolyzer' && entry.rank == phenotypeGene.rank;
        })
        if (matchingRanks.length == 0) {
          ranks.push( {'rank': phenotypeGene.rank, 'source': 'Phenolyzer'});
        }
      })
    }

  }


  getPhenotypeHits(geneName) {
    return this.genePhenotypeHits[geneName];
  }

  setRankedGenes(rankedGenes) {
    let self = this;
    self.rankedGenes = {};
    if (rankedGenes.gtr) {
      rankedGenes.gtr.forEach(function(gtrGene) {
        let theRankedGene = self.rankedGenes[gtrGene.name];
        if (theRankedGene == null) {
          theRankedGene = { 'name': gtrGene.name, 'gtrRank': gtrGene.gtrRank, 'gtrAssociated': gtrGene.gtrAssociated};
          self.rankedGenes[gtrGene.name] = theRankedGene;
        } else {
          theRankedGene.gtrRank = gtrGene.gtrRank;
          theRankedGene.gtrAssociated = gtrGene.gtrAssociated;
        }
      })
    }
    if (rankedGenes.phenolyzer) {
      rankedGenes.phenolyzer.forEach(function(phGene) {
        let theRankedGene = self.rankedGenes[phGene.name];
        if (theRankedGene == null) {
          theRankedGene = { 'name': phGene.name, 'phenolyzerRank': phGene.phenolyzerRank};
          self.rankedGenes[phGene.name] = theRankedGene;
        } else {
          theRankedGene.phenolyzerRank = phGene.phenolyzerRank;
        }
      })
    }
    if (rankedGenes.hpo) {
      rankedGenes.hpo.forEach(function(hpoGene) {
        let theRankedGene = self.rankedGenes[hpoGene.name];
        if (theRankedGene == null) {
          theRankedGene = { 'name': hpoGene.name, 'hpoRank': hpoGene.hpoRank};
          self.rankedGenes[hpoGene.name] = theRankedGene;
        } else {
          theRankedGene.hpoRank = hpoGene.hpoRank;
        }
      })
    }
  }

  getGeneRank(geneName) {
    if (this.rankedGenes) {
      return this.rankedGenes[geneName];
    } else {
      var rank = this.geneNames.indexOf(geneName) + 1;
      return { 'name': geneName, 'genericRank': rank};
    }
  }

  promiseAddGeneName(theGeneName) {
    let me = this;

    return new Promise(function(resolve, reject) {

      let geneName = theGeneName.toUpperCase();

      if (me.geneNames.indexOf(geneName) < 0) {
        me.geneNames.push(geneName);
        me.sortedGeneNames.push(geneName);
        me.promiseGetGeneObject(geneName)
        .then(function() {
          resolve();
        })
        .catch(function(error) {
          if (error.hasOwnProperty('message')) {
            console.log(error.message)
            me.dispatchEvent('alertIssued', ['error', error.message, geneName]);
          } else {
            console.log(error)
            me.dispatchEvent('alertIssued', ['error', error.toString(), geneName]);
          }
          reject()
        })
      } else {
        resolve();
      }

    })
  }

  setAllKnownGenes(allKnownGenes) {
    var me = this;
    me.allKnownGenes = allKnownGenes;
    me.allKnownGeneNames = {};
    me.allKnownGenes.forEach(function(gene) {
      me.allKnownGeneNames[gene.gene_name.toUpperCase()] = gene;
    })
  }

  ACMGGenes() {
    this.promiseCopyPasteGenes(this.getGenePanel("ACMG 59").join(","));
  }


  promiseCopyPasteGenes(genesString, options={replace:true, warnOnDup: true}) {
    var me = this;

    return new Promise(function(resolve, reject) {

      me._promiseCopyPasteGenesImpl(genesString, options)
      .then(function() {
        me.getNCBIGeneSummariesForceWait(me.geneNames)

        var promises = [];
        me.geneNames.forEach(function(geneName) {
          promises.push(me.promiseGetGeneObject(geneName));
          promises.push(me.promiseGetGenePhenotypes(geneName));
        })

        return Promise.all(promises)
      })
      .then(function() {
        resolve();
      })
      .catch(function(error) {
        console.log(error);
        resolve();
      })

    })


 }

 getCopyPasteGeneCount(genesString) {
    if (genesString == "") {
      return 0;
    }
    genesString = genesString.replace(/\s*$/, "");
    var geneNameList = genesString.split(/(?:\s+|,\s+|,|\n)/g);
    return geneNameList.length;
 }

 _promiseCopyPasteGenesImpl(genesString, options={replace: true, warnOnDup: true}) {
    var me = this;

    return new Promise(function(resolve, reject) {

      genesString = genesString.replace(/\s*$/, "");
      var geneNameList = genesString.split(/(?:\s+|,\s+|,|\n)/g);



      var genesToAdd = [];
      var unknownGeneNames = {};
      var duplicateGeneNames = {};
      var promises = [];
      geneNameList.forEach( function(geneName) {
        if (geneName.trim().length > 0) {
          let p = me.promiseIsValidGene(geneName.trim())
          .then(function(isValid) {
            if (isValid) {
              // Make sure this isn't a duplicate.  If we are not replacing the current genes,
              // make sure to check for dups in the existing gene list as well.
              if (genesToAdd.indexOf(geneName.trim().toUpperCase()) < 0
                  && (options.replace || me.geneNames.indexOf(geneName.trim().toUpperCase()) < 0)) {
                genesToAdd.push(geneName.trim().toUpperCase());
              } else {
                duplicateGeneNames[geneName.trim().toUpperCase()] = true;
              }
            } else {
              unknownGeneNames[geneName.trim().toUpperCase()] = true;
              if (genesToAdd.indexOf(geneName.trim().toUpperCase()) < 0
                  && (options.replace || me.geneNames.indexOf(geneName.trim().toUpperCase()) < 0)) {
                genesToAdd.push(geneName.trim().toUpperCase());
              }
            }
          })
          promises.push(p);
        }
      });

      Promise.all(promises)
      .then(function() {

        if (options.replace) {
          me.geneNames = [];
          me.sortedGeneNames = [];
        }

        genesToAdd.forEach(function(geneName) {
          me.geneNames.push(geneName);
          me.sortedGeneNames.push(geneName);
        })



        if (Object.keys(unknownGeneNames).length > 0) {
          var message = "Bypassing unknown genes: " + Object.keys(unknownGeneNames).join(", ") + ".";
          me.dispatch.alertIssued("warning", message, Object.keys(unknownGeneNames).join(", "), Object.keys(unknownGeneNames))
        }

        if (Object.keys(duplicateGeneNames).length > 0 && options.warnOnDup) {
          var message = "Bypassing duplicate gene name(s): " + Object.keys(duplicateGeneNames).join(", ") + ".";
          me.dispatch.alertIssued("warning", message, null, Object.keys(duplicateGeneNames))
        }

        if (me.limitGenes) {
          if (me.globalApp.maxGeneCount && me.geneNames.length > me.globalApp.maxGeneCount) {
            var bypassedCount = me.geneNames.length - me.globalApp.maxGeneCount;
            me.geneNames = me.geneNames.slice(0, me.globalApp.maxGeneCount);
            let msg = "Due to browser cache limitations, only the first " + me.globalApp.maxGeneCount
              + " genes were added. "
              + bypassedCount.toString()
              + " "
              + (bypassedCount == 1 ? "gene" : "genes")
              +  " bypassed.";
            me.dispatch.alertIssued("warning", msg)
          }

        }

        resolve();

      })
      .catch(function(error) {
        reject(error);
      })


    })

 }


  setDangerSummary(geneName, dangerSummary) {
    if (geneName == null) {
      return;
    }
    delete this.geneDangerSummaries[geneName];
    this.geneDangerSummaries[geneName.toUpperCase()] = dangerSummary;
    this.dispatch.geneDangerSummarized(dangerSummary);
  }

  getDangerSummary(geneName) {
    if (geneName == null) {
      return
    }
    return this.geneDangerSummaries[geneName.toUpperCase()];
  }


  clearGeneToLatestTranscript() {
    this.geneToLatestTranscript = {}
  }


  promiseLoadClinvarGenes() {
    let me = this;
    var p = new Promise(function(resolve, reject) {

      me.clinvarGenes = {};

      $.ajax({
          url: me.globalApp.clinvarGenesUrl,
          type: "GET",
          crossDomain: true,
          dataType: "text",
          success: function( res ) {
            if (res && res.length > 0) {
              let recs = res.split("\n");
              var firstTime = true;
              recs.forEach(function(rec) {
                if (firstTime) {
                  // ignore col headers
                  firstTime = false;
                } else {
                  var fields = rec.split("\t");
                  me.clinvarGenes[fields[0]] = +fields[1];
                }
              })

              resolve();
            } else {
              reject("Empty results returned from promiseLoadClinvarGenes");

            }

          },
          error: function( xhr, status, errorThrown ) {
            console.log( "Error: " + errorThrown );
            console.log( "Status: " + status );
            console.log( xhr );
            reject("Error " + errorThrown + " occurred in promiseLoadClinvarGenes() when attempting get clinvar gene counts ");
          }
      });

    });

  }



  getRidOfDuplicates(genes) {
    let me = this;
    var sortedGenes = genes.sort( function(g1, g2) {
      if (g1.gene_name < g2.gene_name) {
        return -1;
      } else if (g1.gene_name > g2.gene_name) {
        return 1;
      } else {
        return 0;
      }
    });
    // Flag gene objects with same name
    for (var i =0; i < sortedGenes.length - 1; i++) {
          var gene = sortedGenes[i];


          var nextGene = sortedGenes[i+1];
          if (i == 0) {
            gene.dup = false;
          }
          nextGene.dup = false;

          if (gene.gene_name == nextGene.gene_name && gene.refseq == nextGene.refseq && gene.gencode == nextGene.gencode) {
            nextGene.dup = true;
        }

        // Some more processing to gather unique gene sets and add field 'name'
        gene.name = gene.gene_name;
        if (gene.refseq != gene.gencode) {
          if (gene.refseq) {
            me.refseqOnly[gene.gene_name] = gene;
          } else {
            me.gencodeOnly[gene.gene_name] = gene;
          }
        }
    }
    return sortedGenes.filter(function(gene) {
      return gene.dup == false;
    });
  }

  getTranscript(geneObject, transcriptId) {
    var theTranscripts = geneObject.transcripts.filter(function(transcript) {
      return transcript.transcript_id == transcriptId;
    });
    return theTranscripts.length > 0 ? theTranscripts[0] : null;
  }

  getCanonicalTranscript(theGeneObject) {
    let me = this;
    var geneObject = theGeneObject != null ? theGeneObject : window.gene;
    var canonical;

    if (geneObject.transcripts == null || geneObject.transcripts.length == 0) {
      return null;
    }
    var order = 0;
    geneObject.transcripts.forEach(function(transcript) {
      transcript.isCanonical = false;
      var cdsLength = 0;
      if (transcript.features != null) {
        transcript.features.forEach(function(feature) {
          if (feature.feature_type == 'CDS') {
            cdsLength += Math.abs(parseInt(feature.end) - parseInt(feature.start));
          }
        })
        transcript.cdsLength = cdsLength;
      } else {
        transcript.cdsLength = +0;
      }
      transcript.order = order++;

    });
    var sortedTranscripts = geneObject.transcripts.slice().sort(function(a, b) {
      var aType = +2;
      var bType = +2;
      if (a.hasOwnProperty("transcript_type") && a.transcript_type == 'protein_coding') {
        aType = +0;
        a.type = +0;
      } else if (a.hasOwnProperty("gene_type") && a.gene_type == "gene")  {
        aType = +0;
        a.type = +0;
      } else {
        aType = +1;
        a.type = +1;
      }
      if (b.hasOwnProperty("transcript_type") && b.transcript_type == 'protein_coding') {
        bType = +0;
        b.type = +0;
      } else if (b.hasOwnProperty("gene_type") && b.gene_type == "gene")  {
        bType = +0;
        b.type = +0;
      } else {
        bType = +1;
        b.type = +1;
      }

      var aManeSelect = +1;
      var bManeSelect = +1;
      if (a.hasOwnProperty("is_mane_select") && a.is_mane_select == 'true') {
        aManeSelect = +0;
      }
      if (b.hasOwnProperty("is_mane_select") && b.is_mane_select == 'true') {
        bManeSelect = +0;
      }


      var aLevel = +2;
      var bLevel = +2;
      if (me.geneSource.toLowerCase() == 'refseq') {
        if (a.transcript_id.indexOf("NM_") == 0 ) {
          aLevel = +0;
          a.level = +0;
        }
        if (b.transcript_id.indexOf("NM_") == 0 ) {
          bLevel = +0;
          b.level = +0;
        }
      } else {
        // Don't consider level for gencode as this seems to point to shorter transcripts many
        // of the times.
        //aLevel = +a.level;
        //bLevel = +b.level;
      }


      var aSource = +2;
      var bSource = +2;
      if (me.geneSource.toLowerCase() =='refseq') {
        if (a.annotation_source == 'BestRefSeq' ) {
          aSource = +0;
          a.source = +0;
        }
        if (b.annotation_source == 'BestRefSeq' ) {
          bSource = +0;
          b.source = +0;
        }
      }

      a.sort = aType + ' ' + aLevel + ' ' + aSource + ' ' + a.cdsLength + ' ' + a.order;
      b.sort = bType + ' ' + bLevel + ' ' + bSource + ' ' + b.cdsLength + ' ' + b.order;

      if (aManeSelect == bManeSelect) {
        if (aType == bType) {
          if (aLevel == bLevel) {
            if (aSource == bSource) {
              if (+a.cdsLength == +b.cdsLength) {
                // If all other sort criteria is the same,
                // we will grab the first transcript listed
                // for the gene.
                if (a.order == b.order) {
                  return 0;
                } else if (a.order < b.order) {
                  return -1;
                } else {
                  return 1;
                }
                return 0;
              } else if (+a.cdsLength > +b.cdsLength) {
                return -1;
              } else {
                return 1;
              }
            } else if ( aSource < bSource ) {
              return -1;
            } else {
              return 1;
            }
          } else if (aLevel < bLevel) {
            return -1;
          } else {
            return 1;
          }
        } else if (aType < bType) {
          return -1;
        } else {
          return 1;
        }
      } else if (aManeSelect < bManeSelect) {
        return -1;
      } else {
        return 1;
      }
    });
    canonical = sortedTranscripts[0];
    let nextTranscript = sortedTranscripts.length > 1 ? sortedTranscripts[1] : null
    if (canonical) {
      canonical.isCanonical = true;
      canonical.canonical_reason = ''
      if (canonical.is_mane_select && canonical.is_mane_select == 'true') {
        canonical.canonical_reason = 'MANE SELECT'
      }
    }
    return canonical;
  }

  addUnionedTranscript(geneObject) {
    let self = this;
    let featureMap = {}

    let existing = geneObject.transcripts.filter(function(transcript) {
      return transcript.transcript_type == 'UNIONED';
    })
    if (existing.length > 0) {
      return existing[0];
    }

    // First map all of the coding regions in the MANE transcript
    let canonicalTranscript = $.extend({'UNIONED': true}, self.getCanonicalTranscript(geneObject))
    let cr = self.getCodingRegions(canonicalTranscript)
    let codingRegions = cr.map(function(feature) {
      return $.extend({'key': feature.start + "-" + feature.end}, feature);
    })
    codingRegions.forEach(function(feature) {
      featureMap[feature.key] = feature;
    })

    // Now hash any additional coding regions not found in the MANE transcript
    geneObject.transcripts.forEach(function(transcript) {
      if (!transcript.DEFAULT) {
        let exons = self._getSortedExonsForTranscript(transcript)
        let codingRegions = exons.filter(function(feature) {
          return feature.feature_type != 'UTR';
        }).map(function(feature) {
          return $.extend({'key': feature.start + "-" + feature.end}, feature);
        })
        codingRegions.forEach(function(feature) {
          if (featureMap[feature.key] ==  null) {
            feature.ADDITIONAL = true;
            featureMap[feature.key] = feature;
          }
        })
      }
    })

    let start = 999999;
    let end = 0;
    let unionedTranscript = {'transcript_id': geneObject.gene_name + ' Unioned',
                             'UNIONED': true,
                             'chr':  geneObject.chr,
                             'start': null,
                             'end': null,
                             'strand': geneObject.strand,
                             'feature_type': 'transcript',
                             'transcript_type': 'UNIONED',
                             'source': 'iobio',
                             'features': []
                           }
    Object.keys(featureMap).forEach(function(key) {
      let feature = featureMap[key];
      unionedTranscript.features.push(feature);
      if (+feature.start < start) {
        start = +feature.start;
      }
      if (+feature.end > end) {
        end = +feature.end;
      }
    })
    unionedTranscript.start = start;
    unionedTranscript.end   = end;

    geneObject.transcripts.push(unionedTranscript);
    return unionedTranscript;
  }

  createSpliceJunctions(bedRecords, geneObject, transcript, options={'allTranscripts': true}) {
    let self = this;
    let spliceJunctions = bedRecords.map(function(bedRow) {

      let donorPos      =  bedRow.strand == "+" ? +bedRow.start : +bedRow.end;
      let acceptorPos   =  bedRow.strand == "+" ? +bedRow.end   : +bedRow.start;

      let matchedExons = self.locateExons(geneObject, transcript, donorPos, acceptorPos, options)

      let exonMatchDonor            = matchedExons.donor;
      let exonMatchAcceptor         = matchedExons.acceptor;
      let matchesOnOtherTranscripts = matchedExons.matchesOnOtherTranscripts;


      let spliceKind           = "canonical"
      let donor                = null
      let acceptor             = null
      let countSkippedExons    = 0;

      if (exonMatchDonor && exonMatchAcceptor) {
        countSkippedExons = Math.abs(+exonMatchAcceptor.exon.number - +exonMatchDonor.exon.number) - 1;
        donor    = {'exon': exonMatchDonor.exon,    pos: +donorPos,    'transcript': exonMatchDonor.transcript,    'delta': exonMatchDonor.delta,    'status': exonMatchDonor.status}
        acceptor = {'exon': exonMatchAcceptor.exon, pos: +acceptorPos, 'transcript': exonMatchAcceptor.transcript, 'delta': exonMatchAcceptor.delta, 'status': exonMatchAcceptor.status}
      } else {
        if (exonMatchDonor == null && exonMatchAcceptor == null) {
          let donorClosest     = self.locateExonsBetween(transcript, +donorPos, 'donor');
          let acceptorClosest  = self.locateExonsBetween(transcript, +acceptorPos, 'acceptor');
          if (donorClosest && donorClosest.closestExon && acceptorClosest && acceptorClosest.closestExon) {
            countSkippedExons = Math.abs(+donorClosest.closestExon.number - +acceptorClosest.closestExon.number) - 1;
          }
          donor    = {'exonClosest': donorClosest.closestExon,      pos: +donorPos,    'transcript': donorClosest.transcript,    'delta': donorClosest.delta,   'status': donorClosest.status}
          acceptor = {'exonClosest': acceptorClosest.closestExon,   pos: +acceptorPos, 'transcript': acceptorClosest.transcript, 'delta': acceptorClosest.delta,'status': acceptorClosest.status}
        } else if (exonMatchDonor == null) {
          let donorClosest = self.locateExonsBetween(transcript, +donorPos, 'donor');
          if (donorClosest && donorClosest.closestExon) {
            countSkippedExons = Math.abs(+donorClosest.closestExon.number - +exonMatchAcceptor.exon.number) - 1
          }
          donor    = {'exonClosest': donorClosest.closestExon, pos: +donorPos,    'transcript': donorClosest.transcript,      'delta': donorClosest.delta,     'status': donorClosest.status}
          acceptor = {'exon':        exonMatchAcceptor.exon,   pos: +acceptorPos, 'transcript': exonMatchAcceptor.transcript, 'delta': exonMatchAcceptor.delta,'status': exonMatchAcceptor.status}

        } else if (exonMatchAcceptor == null) {
          let acceptorClosest   = self.locateExonsBetween(transcript, +acceptorPos, 'acceptor');
          if (acceptorClosest && acceptorClosest.closestExon) {
            countSkippedExons = Math.abs(+exonMatchDonor.exon.number - +acceptorClosest.closestExon.number) - 1
          }
          donor    = {'exon': exonMatchDonor.exon,                pos: +donorPos,    'transcript': exonMatchDonor.transcript, 'delta': exonMatchDonor.delta, 'status': exonMatchDonor.status }
          acceptor = {'exonClosest': acceptorClosest.closestExon, pos: +acceptorPos, 'transcript': acceptorClosest.transcript,'delta': acceptorClosest.delta, 'status': acceptorClosest.status}
        }
      }

      if (donor.status == 'cryptic-site' || acceptor.status == 'cryptic-site') {
        spliceKind = 'cryptic-site'
      }

      if (countSkippedExons > 0 && spliceKind == 'canonical') {
        spliceKind = 'exon-skipping'
      }

      let donorLabel = "";
      if (donor.exon) {
        donorLabel =  'Exon ' + donor.exon.number
        if (donor.delta) {
          donorLabel += " " + (donor.delta > 0 ? "+" : "") + donor.delta + ""
        }
      } else  {
        donorLabel = 'Intronic '  + geneObject.chr + ":" +  d3.format(",")(donor.pos);
      }
      let acceptorLabel = "";
      if (acceptor.exon) {
        acceptorLabel = 'Exon ' + acceptor.exon.number
        if (acceptor.delta) {
          acceptorLabel += " " + (acceptor.delta > 0 ? "+" : "") + acceptor.delta + ""
        }
      } else  {
        acceptorLabel = 'Intronic ' + geneObject.chr + ":" + d3.format(",")(acceptor.pos);
      }
      donor.label = donorLabel;
      acceptor.label = acceptorLabel;

      let junctionTranscript = null;
      let isPreferredTranscript = true;
      if (donor.transcript) {
        junctionTranscript = donor.transcript;
      } else if (acceptor.transcript) {
        junctionTranscript = acceptor.transcript;
      }
      if (junctionTranscript && junctionTranscript.transcript_id != transcript.transcript_id) {
        isPreferredTranscript = false;
      }

      let label = donorLabel +
                  "      >      " +
                  acceptorLabel +
                  (isPreferredTranscript == false ? '      (' + junctionTranscript.transcript_id + ')' : '');

      return {
        'key':               donor.pos + ">" + acceptor.pos + "(" + bedRow.strand + ")",
        'donor':             donor,
        'acceptor':          acceptor,
        'label':             label,

        'countSkippedExons': countSkippedExons,

        'spliceKind':        spliceKind,

        'motif':             bedRow.annots ? bedRow.annots.motif : null,
        'strand':            bedRow.strand,
        'readCount':        +bedRow.score,

        'matchesOnOtherTranscripts': matchesOnOtherTranscripts,
        'junctionTranscript':        junctionTranscript,
        'isPreferredTranscript':     isPreferredTranscript,

        // These are convenience fields to make sorting and grouping easier
        // For example, we want to order by donor site when the the junction
        // is one the forward strand and by acceptor site when the junction is
        // on the reverse strand. You can think of posLow as the position with
        // the lowest coordinate and posHigh and the position with the highest
        // coordinate
        'posLow':  bedRow.strand == '-' ? acceptor.pos : donor.pos,
        'posHigh': bedRow.strand == '-' ? donor.pos    : acceptor.pos,
        // Target site low indicates if the posLow is from the donor or acceptor site;
        // target site high indicates if the posHigh is from the donor or acceport site;
        'targetSiteLow':  bedRow.strand == '-' ? 'acceptor' : 'donor',
        'targetSiteHigh': bedRow.strand == '-' ? 'donor'    : 'acceptor',
       }
    })
    .filter(function(spliceJunction) {
      let matchesCount = spliceJunction.readCount > 0
      let matchesStrand = (spliceJunction.strand == 'undefined' || spliceJunction.strand == "" || geneObject.strand == spliceJunction.strand) ;
      return matchesCount;
    })
    .sort(function(a,b) {
      return a.posLow - b.posLow;
    })

    self.geneToSpliceJunctionObjects[geneObject.gene_name] = spliceJunctions;
    let summary = self.summarizeSpliceJunctions(geneObject, transcript)

    // Update the splice junctions with the z-score
    spliceJunctions.forEach(function(sj) {
      let zScore  = (sj.readCount -  summary.meanReadCountCanonical) / summary.stdReadCountCanonical;
      sj.zScore = Math.round((zScore + Number.EPSILON) * 100) / 100
    })
    return spliceJunctions;

  }

  summarizeSpliceJunctions(geneObject, transcript) {
    let self = this;
    let summary = null;
    let spliceJunctions = self.geneToSpliceJunctionObjects[geneObject.gene_name];
    if (spliceJunctions) {

      let crypticSiteSplice = spliceJunctions.filter(function(spliceJunction) {
        return spliceJunction.spliceKind == 'cryptic-site';
      })
      .sort(function(a,b) {
        return b.readCount - a.readCount;
      })

      let exonSkippingSplice = spliceJunctions.filter(function(spliceJunction) {
        return spliceJunction.spliceKind == 'exon-skipping';
      })
      let canonicalSplice = spliceJunctions.filter(function(spliceJunction) {
        return spliceJunction.spliceKind == 'canonical';
      })
      let meanReadCountCanonical = Math.round(d3.mean(canonicalSplice, d => +d.readCount))
      let stdReadCountCanonical  = Math.round(d3.deviation(canonicalSplice, d => +d.readCount))
      let minReadCountCanonical  = d3.min(canonicalSplice, d => d.readCount)
      let maxReadCountCanonical  = d3.max(canonicalSplice, d => d.readCount)

      let meanReadCountCrypticSite = Math.round(d3.mean(crypticSiteSplice, d => +d.readCount))
      let stdReadCountCrypticSite  = Math.round(d3.deviation(crypticSiteSplice, d => +d.readCount))
      let minReadCountCrypticSite  = d3.min(crypticSiteSplice, d => d.readCount)
      let maxReadCountCrypticSite  = d3.max(crypticSiteSplice, d => d.readCount)

      summary = {
          'gene':                   geneObject,
          'canonical':              canonicalSplice,
          'crypticSite':            crypticSiteSplice,
          'exonSkippingSplice':     exonSkippingSplice,
          'count':                  spliceJunctions.length,

          'meanReadCountCanonical': meanReadCountCanonical,
          'stdReadCountCanonical':  stdReadCountCanonical,
          'minReadCountCanonical':  minReadCountCanonical,
          'maxReadCountCanonical':  maxReadCountCanonical,

          'meanReadCountCrypticSite': meanReadCountCrypticSite,
          'stdReadCountCrypticSite':  stdReadCountCrypticSite,
          'minReadCountCrypticSite':  minReadCountCrypticSite,
          'maxReadCountCrypticSite':  maxReadCountCrypticSite
      }
      self.geneToSpliceJunctionSummary[geneObject.gene_name] = summary;
    }
    return summary;
  }


  locateExons(geneObject, preferredTranscript, donorPos, acceptorPos, options) {
    let self = this;

    let exonMatchDonor = null;
    let exonMatchAcceptor = null;

    // Check for matching exons on donor and acceptor site on preferred (MANE SELECT)
    // transcript first. Site must be positioned on exon boundary (isExact=true)
    let exonDonorPreferred    = self._locateExon(preferredTranscript, +donorPos,    'donor',    true);
    let exonAcceptorPreferred = self._locateExon(preferredTranscript, +acceptorPos, 'acceptor', true);


    let matchesOnOtherTranscripts = [];

    // If both the donor and acceptor site are found on exons on the preferred transcript,
    // continue on with these identified exons
    if (exonDonorPreferred && exonAcceptorPreferred) {
      exonMatchDonor   = exonDonorPreferred;
      exonMatchAcceptor = exonAcceptorPreferred;
    } else if (options && options.allTranscripts) {

      // Loop through all of the transcripts, trying to find a matching
      // exon for the donor and acceptor site
      let transcripts = geneObject.transcripts.filter(function(transcript) {
        return transcript.transcript_id != preferredTranscript.transcript_id
      })

      let idx = 0;
      for (idx = 0; idx < transcripts.length; idx++) {
        let transcript = transcripts[idx]
        let exonDonor    = self._locateExon(transcript, +donorPos,    'donor',    true);
        let exonAcceptor = self._locateExon(transcript, +acceptorPos, 'acceptor', true);
        if (exonDonor && exonAcceptor) {
          // If we match on the donor and acceptor exon on the same transcript,
          // we have match. Keep the first match and keep a list of
          // all of the transcripts with matches.
          if (!exonMatchDonor && !exonMatchAcceptor) {
            exonMatchDonor    = exonDonor;
            exonMatchAcceptor = exonAcceptor;
          }
          matchesOnOtherTranscripts.push({'matchDonor': exonDonor,
                                          'matchAcceptor': exonAcceptor})
        }
      }
    }

    // If we found an exact match on the donor and acceptor site for a transcript,
    // use those; otherwise, fuzzy match on preferred transcript
    if (exonMatchDonor && exonMatchAcceptor) {

    } else if (exonDonorPreferred) {
      exonMatchDonor = exonDonorPreferred
      exonMatchAcceptor = self._locateExon(preferredTranscript, +acceptorPos, 'acceptor', false);

    } else if (exonAcceptorPreferred) {
      exonMatchAcceptor = exonAcceptorPreferred;
      exonMatchDonor    = self._locateExon(preferredTranscript, +donorPos,    'donor',    false);
    } else {
      exonMatchAcceptor = self._locateExon(preferredTranscript, +acceptorPos, 'acceptor', false);
      exonMatchDonor    = self._locateExon(preferredTranscript, +donorPos,    'donor',    false);

    }

    return {'donor': exonMatchDonor,
            'acceptor': exonMatchAcceptor,
            'matchesOnOtherTranscripts': matchesOnOtherTranscripts}

  }


  _locateExon(transcript, position, site, isExact) {
    if (transcript.exons) {

      // The position provided in the bed has an offset.
      //   For plus strand:
      //     donor site    is stated as position at exon end
      //     acceptor site is stated as position at exon start - 1
      //   For minus strand
      //     donor site    is stated as position at exon start - 1
      //     acceptor site is stated as position at exon end
      let thePosition = position;
      if (site == 'acceptor') {
        if (transcript.strand == '-') {
          // The acceptor position is stated as the exon end.
          // Adjust it to the correct coordinates, which is 1 bp after
          // the exon end
          thePosition = thePosition + 1;
        } else {
          // The acceptor position is stated as 1 bp before the exon start.
          // Adjust it to the correct coordinates (subtract 1), which is 2 bp
          // before the exon start (considering the splice site is 2 bp long)
          thePosition = thePosition - 1;
        }
      } else if (site == 'donor') {
        if (transcript.strand == '-') {
          // The donor position is stated as 1 bp before the exon start.
          // Adjust it to the correct coordinates (subtract 1), which is 2 bp
          // before the exon start (considering the splice site is 2 bp long)
          thePosition = thePosition - 1;
        } else {
          // The donor position is stated as the exon end.
          // Adjust it to the correct coordinates, which is 1 bp after
          // the exon end
          thePosition = thePosition + 1;
        }
      }
      var matched = transcript.exons.map(function(exon) {
        let start = null;
        let end = null;
        let delta = null;
        if (site == 'acceptor') {
          if (transcript.strand == '-') {
            // The acceptor site is 1 bp after the exon end.
            start = isExact ? +exon.end + 1 : +exon.start;
            end   = isExact ? +exon.end + 1 : +exon.end;
            delta = (thePosition - end);
          } else {
            // The acceptor site is 2 bp before the exon start
            start = isExact ? +exon.start - 2 : +exon.start;
            end   = isExact ? +exon.start - 2 : +exon.end;
            delta = thePosition - start;
          }
        } else if (site == 'donor') {
          if (transcript.strand == '-') {
            // The donor site is 2 bp before the exon start
            start = isExact ? +exon.start - 2 : +exon.start;
            end   = isExact ? +exon.start - 2 : +exon.end;
            delta = (thePosition - start);
          } else {
            // The donor site is 1 bp after the exon end
            start = isExact ? +exon.end + 1 : +exon.start;
            end   = isExact ? +exon.end + 1 : +exon.end;
            delta = thePosition - end;
          }
        }
        return {'start': start, 'end': end, 'exon': exon, 'delta': delta}
      })
      .filter(function(boundary) {
        return boundary.start <= thePosition && boundary.end >= thePosition;
      })
      if (matched.length > 0) {
        return {'transcript': transcript,
                'exon': matched[0].exon,
                'delta': matched[0].delta,
                'boundaryStart': matched[0].start,
                'boundaryEnd': matched[0].end,
                'status': isExact ? 'canonical' : 'cryptic-site' };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }





  locateExonsBetween(transcript, position, spliceSite) {
    let betweenExons = null;
    let exonClosest = null;
    if (transcript.strand == '-') {
      for (var i = transcript.exonsOnly.length-1; i >= 1; i--) {
        let exon     = transcript.exons[i]
        let nextExon = transcript.exons[i-1]

        let exonEnd         = +exon.end;
        let nextExonStart   = +nextExon.start - 1;
        if (position >= exonEnd && position <= nextExonStart) {
          betweenExons = [exon, nextExon];
          if (spliceSite == 'donor') {
            exonClosest = nextExon;
          } else {
            exonClosest = exon;
          }
          break;
        }
      }
    } else {
      for (var i = 0; i < transcript.exonsOnly.length-1; i++) {
        let exon     = transcript.exons[i]
        let nextExon = transcript.exons[i+1]

        let exonEnd       = +exon.end;
        let nextExonStart = +nextExon.start - 1;
        if (position >= exonEnd && position <= nextExonStart) {
          betweenExons = [exon, nextExon];
          if (spliceSite == 'donor') {
            exonClosest = exon;
          } else {
            exonClosest = nextExon;
          }
          break;
        }
      }
    }
    return {'transcript': transcript,
            'closestExon': exonClosest,
            'betweenExons': betweenExons,
            'status': 'cryptic-site'};
  }

  getCanonicalTranscriptOld(theGeneObject) {
    let me = this;

    var geneObject = theGeneObject != null ? theGeneObject : window.gene;
    var canonical;
    var maxCdsLength = 0;
    geneObject.transcripts.forEach(function(transcript) {
      var cdsLength = 0;
      if (transcript.features != null) {
        transcript.features.forEach(function(feature) {
          if (feature.feature_type == 'CDS') {
            cdsLength += Math.abs(parseInt(feature.end) - parseInt(feature.start));
          }
        })
        if (cdsLength > maxCdsLength) {
          maxCdsLength = cdsLength;
          canonical = transcript;
        }
        transcript.cdsLength = cdsLength;
      }

    });

    if (canonical == null) {
      // If we didn't find the canonical (transcripts didn't have features), just
      // grab the first transcript to use as the canonical one.
      if (geneObject.transcripts != null && geneObject.transcripts.length > 0)
      canonical = geneObject.transcripts[0];
    }
    canonical.isCanonical = true;
    return canonical;
  }

  getCodingRegions(transcript) {
    let me = this;
    if (transcript && transcript.features) {
      var codingRegions = me.transcriptCodingRegions[transcript.transcript_id];
      if (codingRegions) {
        return codingRegions;
      }
      codingRegions = [];
      transcript.features.forEach( function(feature) {
        if ($.inArray(feature.feature_type, ['EXON', 'CDS', 'UTR']) !== -1) {
          codingRegions.push($.extend({}, feature));
        }
      });
      me.transcriptCodingRegions[transcript.transcript_id] = codingRegions;
      return codingRegions;
    }
    return [];
  }




  _getSortedExonsForTranscript(transcript) {
    var sortedExons = transcript
      .features.filter(function(feature) {
        return feature.feature_type.toUpperCase() == 'UTR' || feature.feature_type.toUpperCase() == 'CDS';
      })
      .sort(function(feature1, feature2) {

        var compare = 0;
        if (feature1.start < feature2.start) {
          compare = -1;
        } else if (feature1.start > feature2.start) {
          compare = 1;
        } else {
          compare = 0;
        }

        var strandMultiplier = transcript.strand == "+" ? 1 : -1;

        return compare * strandMultiplier;

      })

    var exonCount = 0;
    sortedExons.forEach(function(exon) {
      exonCount++
    })

    var exonNumber = 1;
    sortedExons.forEach(function(exon) {
      exon.exon_number = exonNumber + "/" + exonCount;
      exonNumber++;
    })
    return sortedExons;
  }

  getNCBIGeneSummariesForceWait(geneNames) {
    let me = this;
    let waitSeconds = 0;
    if (Object.keys(me.geneNCBISummaries).length > 0) {
      waitSeconds = 5000;
    }
    setTimeout(function() {
      me.promiseGetNCBIGeneSummaries(geneNames)
    }, waitSeconds);
  }

  promiseGetNCBIGeneSummaries(geneNames) {
    let me = this;
    return new Promise( function(resolve, reject) {

      let unknownGeneInfo = {description: ' ', summary: ' '};

      let theGeneNames = geneNames.filter(function(geneName) {
        return me.geneNCBISummaries[geneName] == null;
      })

      if (theGeneNames.length == 0) {
        resolve();
      } else {

        let searchGeneExpr = "";
        theGeneNames.forEach(function(geneName) {
          var geneInfo = me.geneNCBISummaries[geneName];
          if (geneInfo == null) {
            if (searchGeneExpr.length > 0) {
              searchGeneExpr += " OR ";
            }
            searchGeneExpr += geneName + "[Gene name]";
          }
        })
        var searchUrl = me.NCBI_GENE_SEARCH_URL + "&term=" + "(9606[Taxonomy ID] AND (" + searchGeneExpr + "))";
        me.pendingNCBIRequests[theGeneNames] = true;

        $.ajax( searchUrl )
         .done(function(data) {

            // Now that we have the gene ID, get the NCBI gene summary
            var webenv = data["esearchresult"]["webenv"];
            var queryKey = data["esearchresult"]["querykey"];
            var summaryUrl = me.NCBI_GENE_SUMMARY_URL + "&query_key=" + queryKey + "&WebEnv=" + webenv;
            $.ajax( summaryUrl )
            .done(function(sumData) {
                if (sumData.result == null || sumData.result.uids.length == 0) {
                  if (sumData.esummaryresult && sumData.esummaryresult.length > 0) {
                    sumData.esummaryresult.forEach( function(message) {
                      console.log("Unable to get NCBI gene summary from eutils esummary")
                      console.log(message);
                    });
                  }
                  delete me.pendingNCBIRequests[theGeneNames];
                  reject();

                } else {

                  sumData.result.uids.forEach(function(uid) {
                    var geneInfo = sumData.result[uid];
                    me.geneNCBISummaries[geneInfo.name] = geneInfo;

                  })
                  delete me.pendingNCBIRequests[theGeneNames];
                  resolve();
                }
            })
           .fail(function(error) {
              console.log(error)
              if (me.pendingNCBIRequests && me.pendingNCBIRequests[theGeneNames]) {
                delete me.pendingNCBIRequests[theGeneNames];
              }
              console.log("Error occurred when making http request to NCBI eutils esummary for genes " + geneNames.join(","));
              reject();
            })

          })
          .fail(function(error) {
            console.log(error)
            if (me.pendingNCBIRequests && me.pendingNCBIRequests[theGeneNames]) {
              delete me.pendingNCBIRequests[theGeneNames];
            }
            console.log("Error occurred when making http request to NCBI eutils esearch for gene " + geneNames.join(","));
            reject();
          })

      }

    })


  }


  promiseGetNCBIGeneSummary(geneName) {
    let me = this;
    return new Promise( function(resolve, reject) {

      var geneInfo = me.geneNCBISummaries[geneName];
      let unknownGeneInfo = {description: ' ', summary: ' '};

      if (geneInfo != null && geneInfo.summary != " ") {
        resolve(geneInfo);
      } else {
        // Search NCBI based on the gene name to obtain the gene ID
        var url = me.NCBI_GENE_SEARCH_URL + "&term=" + "(" + geneName + "[Gene name]" + " AND 9606[Taxonomy ID]";
        $.ajax( url )
        .done(function(data) {

          // Now that we have the gene ID, get the NCBI gene summary
          var webenv = data["esearchresult"]["webenv"];
          var queryKey = data["esearchresult"]["querykey"];
          var summaryUrl = me.NCBI_GENE_SUMMARY_URL + "&query_key=" + queryKey + "&WebEnv=" + webenv;
          $.ajax( summaryUrl )
          .done(function(sumData) {
              if (sumData.result == null || sumData.result.uids.length == 0) {
                if (sumData.esummaryresult && sumData.esummaryresult.length > 0) {
                  sumData.esummaryresult.forEach( function(message) {
                    console.log("Unable to get NCBI gene summary from eutils esummary")
                    console.log(message);
                  });
                }
                me.geneNCBISummaries[geneName] = unknownGeneInfo;
                resolve(unknownGeneInfo);

              } else {

                var uid = sumData.result.uids[0];
                var geneInfo = sumData.result[uid];

                me.geneNCBISummaries[geneName] = geneInfo;
                resolve(geneInfo)
              }
          })
          .fail(function() {
            console.log("Error occurred when making http request to NCBI eutils esummary for gene " + geneName);
            me.dispatch.alertIssued("info",
              "Unable to get NCBI gene summary (esummary) for gene <pre>" + geneName + "</pre>", geneName,
              ['Error occurred when making http request to NCBI eutils esummary',summaryUrl])
            me.geneNCBISummaries[geneName] = unknownGeneInfo;
            resolve(unknownGeneInfo);
          })

        })
        .fail(function() {
          console.log("Error occurred when making http request to NCBI eutils esearch for gene " + geneName);
            me.dispatch.alertIssued("info",
              "Unable to get NCBI gene summary (esearch) for gene <pre>" + geneName + "</pre>", geneName,
              ['Error occurred when making http request to NCBI eutils esearch',url])
          me.geneNCBISummaries[geneName] = unknownGeneInfo;
          resolve(geneInfo);
        })
      }
    });

  }

  promiseGetPubMedEntries(theGeneName, options={retmax: 5, useCached: true}) {
    let me = this;
    return new Promise( function(resolve, reject) {

      let theEntry = me.genePubMedEntries[theGeneName];
      if (theEntry && options.useCached) {
        resolve(theEntry)
      }
      else {
        setTimeout(function() {
          let geneName = theGeneName;
          var pubMedEntries = [];
          var searchUrl = me.NCBI_PUBMED_SEARCH_URL  + "&term=" + geneName + "[title/abstract]";
          me.pendingNCBIRequests[geneName] = true;

          $.ajax( searchUrl )
           .done(function(data) {

              // Now that we have the gene ID, get the NCBI gene summary
              var webenv = data["esearchresult"]["webenv"];
              var queryKey = data["esearchresult"]["querykey"];
              var count = data["esearchresult"]["count"]
              var summaryUrl = me.NCBI_PUBMED_SUMMARY_URL + "&query_key=" + queryKey + "&WebEnv=" + webenv + "&retmax=" + options.retmax;
              $.ajax( summaryUrl )
              .done(function(sumData) {
                delete me.pendingNCBIRequests[geneName];

                if (sumData.result != null && sumData.result.uids && sumData.result.uids.length > 0) {
                  sumData.result.uids.forEach(function(uid) {
                    var entry = sumData.result[uid];
                    pubMedEntries.push({uid: uid, title: entry.title, firstAuthor: entry.sortfirstauthor, pubDate: entry.pubdate, source: entry.source})

                  })
                  let theEntry = {geneName: geneName, count: count, entries: pubMedEntries};
                  if (options.useCached) {
                    me.genePubMedEntries[geneName] = theEntry;
                  }
                  resolve(theEntry);
                } else {
                  let theEntry = {geneName: geneName, count: 0, entries: null}
                  if (options.useCached) {
                    me.genePubMedEntries[geneName] = theEntry;
                  }
                  resolve(theEntry)
                }
              })
             .fail(function(error) {
                delete me.pendingNCBIRequests[geneName];
                let msg = "Unable to get PubMed entries for <pre>" + geneName + "</pre>";
                console.log(msg)
                console.log("Error occurred when making http request to NCBI eutils esummary pubmed for gene " + geneName);
                me.dispatch.alertIssued("info", msg, geneName, [error])
                reject();
              })

           })
           .fail(function(error) {
              delete me.pendingNCBIRequests[geneName];

              let msg = "Unable to get PubMed entries for " + geneName;
              console.log(msg);
              console.log("Error occurred when making http request to NCBI eutils esummary pubmed for gene " + geneName);
              me.dispatch.alertIssued("info", msg, geneName, [error])
              reject();
           })

         },
         (Object.keys(me.pendingNCBIRequests).length > 0 ? 5000 : 3000));

      }
    })
  }

  promiseGetClinvarPhenotypes(cohortModel, geneObject, transcript) {
    let self = this;
    return new Promise(function(resolve, reject) {

      let theEntry = self.geneClinvarPhenotypes[geneObject.gene_name];
      if (theEntry) {
        resolve(theEntry)
      } else {
        let geneName = geneObject.gene_name;
        cohortModel.promiseGetClinvarPhenotypes(geneObject, transcript)
        .then(function(data) {
          self.geneClinvarPhenotypes[geneName] = data;
          resolve(data);
        })
        .catch(function(error) {
          reject(error)
        })
      }
    })
  }




  promiseGetOMIMEntries(theGeneName) {
    let self = this;
    return new Promise(function(resolve, reject) {

      let theEntry = self.geneOMIMEntries[theGeneName];
      if (theEntry) {
        resolve(theEntry)
      } else {
        let geneName = theGeneName;
        self._promiseGetOMIMGene(geneName)
        .then(function(data) {
          if (data.phenotypes && data.phenotypes.length > 0) {
            let promises = [];
            let omimEntries = [];
            data.phenotypes.forEach(function(phenotype) {
              let p = self._promiseGetOMIMClinicalSynopsis(data.geneName, phenotype)
              .then(function(data) {
                omimEntries.push(data);
              })
              promises.push(p)
            })
            Promise.all(promises)
            .then(function() {
              let theEntry = {geneName: geneName, omimEntries: omimEntries};
              self.geneOMIMEntries[geneName] = theEntry;
              resolve(theEntry)
            })
          } else {
            let theEntry = {geneName: geneName, omimEntries: null};
            self.geneOMIMEntries[geneName] = theEntry;
            resolve(theEntry)
          }
        })
        .catch(function(error) {
          let msg = "Cannot get OMIM entries for gene <pre>" + theGeneName + "</pre>."
          console.log(msg)
          console.log(error)
          self.dispatch.alertIssued('warning', msg, theGeneName)
          reject(error)
        })

      }

    })

  }


  promiseGetHPOTermsPublicAPI(geneName) {
    let self = this;
    return new Promise(function(resolve, reject) {
      let hpoTerms = self.geneToHPOTerms[geneName];
      if (hpoTerms) {
        resolve(hpoTerms)
      } else {
        self.promiseGetNCBIGeneSummary(geneName)
        .then(function(ncbiSummary) {
          if (ncbiSummary && ncbiSummary.uid) {
            let url = self.HPO_URL + ncbiSummary.uid;
            $.ajax( url )
            .done(function(data) {
              self.geneToHPOTerms[geneName] = data;
              resolve(data)
            })
            .fail(function(error) {
              let msg = "Unable to get hpo terms for gene " + geneName;
              console.log(msg);
              console.log(error)
              self.dispatch.alertIssued("warning", "Cannot get HPO terms for gene <pre>" + geneName + "</pre>", geneName, [error])
              reject(msg + '. Error: ' + error);
            })
          } else {
            self.dispatch.alertIssued("info", "Cannot get HPO terms for gene <pre>" + geneName + "</pre>. Unable to lookup NCBI id for gene.", geneName)
            reject("Unable to get gene HPO terms because lookup of NCBI gene returned empty results.")
          }
        })
      }
    })
  }

  _promiseGetOMIMGene(geneName) {
    let self = this;
    return new Promise(function(resolve, reject) {
      let apiKey = import.meta.env.ENV_OMIM_API_KEY;

      if (apiKey == null || apiKey == "") {
        if (!self.warnedMissingOMIMApiKey) {
          let msg ="Unable to access OMIM.  API key is required in env."
          self.dispatch.alertIssued("warning", msg, geneName)
          self.warnedMissingOMIMApiKey = true;
        }
        resolve();
      } else {
        let url = self.OMIM_URL  + 'entry/search'
          + '?apiKey=' + apiKey
          + '&search=approved_gene_symbol:' + geneName
          + '&format=json'
          + '&retrieve=geneMap'
          + '&start=0'
          + '&limit=10';

        $.ajax( url )
          .done(function(data) {
            let mimNumber = null;
            let phenotypes = null;
            if (data
              && data.omim.searchResponse
              && data.omim.searchResponse.geneMapList
              && data.omim.searchResponse.geneMapList.length > 0) {
              let geneMap = data.omim.searchResponse.geneMapList[0].geneMap;
              mimNumber = geneMap.mimNumber;
              if (geneMap.phenotypeMapList) {
                phenotypes = geneMap.phenotypeMapList.map(function(entry) {
                  return entry.phenotypeMap;
                })
              }
              resolve({geneName: geneName, mimNumber: mimNumber, phenotypes: phenotypes});
            }
            else {
              let msg = "No OMIM entry found for gene " + geneName;
              reject(msg)
            }

          })
          .fail(function(error) {
              let msg = "Unable to get phenotype mim number OMIM for gene " + geneName;
              console.log(msg);
              console.log(error)
              self.dispatch.alertIssued("warning", msg, geneName)
              reject(msg + '. Error: ' + error);
          })

      }

    })
  }

  _promiseGetOMIMClinicalSynopsis(geneName, phenotype) {
    let self = this;
    return new Promise(function(resolve, reject) {
      let apiKey = import.meta.env.ENV_OMIM_API_KEY;

      let url = self.OMIM_URL  + 'clinicalSynopsis'
        + '?apiKey=' + apiKey
        + '&mimNumber=' + phenotype.phenotypeMimNumber
        + '&include=clinicalSynopsis'
        + '&format=json';

      $.ajax( url )
        .done(function(data) {
          let clinicalSynopsis = null;
          if (data && data.omim.clinicalSynopsisList && data.omim.clinicalSynopsisList.length > 0) {
            clinicalSynopsis = data.omim.clinicalSynopsisList[0].clinicalSynopsis;
          }
          resolve({geneName: geneName, phenotype: phenotype, clinicalSynopsis: clinicalSynopsis});
        })
        .fail(function(error) {
            let msg = "Unable to get clinical synopsisi from OMIM " + url;
            console.log(msg);
            console.log(error)
            reject(msg + '. Error: ' + error);
        })
    })
  }


  _setTranscriptExonNumbers(transcript, sortedExons) {
    // Set the exon number on each UTR and CDS within the corresponding exon
    transcript.features.forEach(function(feature) {
      if (feature.feature_type.toUpperCase() == 'CDS' || feature.feature_type.toUpperCase() == 'UTR') {
        sortedExons.forEach(function(exon) {
          if (feature.start >= exon.start && feature.end <= exon.end) {
            feature.exon_number = exon.exon_number;
          }
        })
      }
    })
  }

  clearAllGenes() {
    let self = this;
    let genesToRemove = [];
    self.geneNames.forEach(function(geneName) {
      genesToRemove.push(geneName);
    })
    genesToRemove.forEach(function(geneNameToRemove) {
      self.removeGene(geneNameToRemove)
    })
  }

  removeGene(geneName) {
    let self = this;

    var index = self.geneNames.indexOf(geneName);
    if (index >= 0) {
      self.geneNames.splice(index, 1);
    }

    index = self.sortedGeneNames.indexOf(geneName);
    if (index >= 0) {
      self.sortedGeneNames.splice(index, 1);
    }

    if (self.geneDangerSummaries && self.geneDangerSummaries.hasOwnProperty(geneName)) {
      delete self.geneDangerSummaries[geneName];
    }
    if (self.genePhenotypes && self.genePhenotypes.hasOwnProperty(geneName)) {
      delete self.genePhenotypes[geneName];
    }
    if (self.geneToEnsemblId && self.geneToEnsemblId.hasOwnProperty(geneName)) {
      delete self.geneToEnsemblId[geneName];
    }

    if (self.geneToHPOTerms && self.geneToHPOTerms.hasOwnProperty(geneName)) {
      delete self.geneToHPOTerms[geneName]
    }

    if (self.geneToSpliceJunctionRecords && self.geneToSpliceJunctionRecords.hasOwnProperty(geneName)) {
      delete self.geneToSpliceJunctionRecords[geneName];
    }
    if (self.geneToSpliceJunctionObjects && self.geneToSpliceJunctionObjects.hasOwnProperty(geneName)) {
      delete self.geneToSpliceJunctionObjects[geneName];
    }
    if (self.geneToSpliceJunctionSummary && self.geneToSpliceJunctionSummary.hasOwnProperty(geneName)) {
      delete self.geneToSpliceJunctionSummary[geneName];
    }

    if (self.geneObjects && self.geneObjects.hasOwnProperty(geneName)) {
      delete self.geneObjects[geneName];
    }

    if (self.geneNCBISummaries && self.geneNCBISummaries.hasOwnProperty(geneName)) {
      delete self.geneNCBISummaries[geneName];
    }
    if (self.geneOMIMEntries && self.geneOMIMEntries.hasOwnProperty(geneName)) {
      delete self.geneOMIMEntries[geneName];
    }
    if (self.genePubMedEntries && self.genePubMedEntries.hasOwnProperty(geneName)) {
      delete self.genePubMedEntries[geneName];
    }
    if (self.geneToLatestTranscript && self.geneToLatestTranscript.hasOwnProperty(geneName)) {
      delete self.geneToLatestTranscript[geneName];
    }
    if (self.geneClinvarPhenotypes && self.geneClinvarPhenotypes.hasOwnProperty(geneName)) {
      delete self.geneClinvarPhenotypes[geneName];
    }
  }


  promiseGetGeneEnsemblId(geneName) {
    let self = this;
    return new Promise(function(resolve, reject) {
      let ensemblGeneId = self.geneToEnsemblId[geneName]
      if (ensemblGeneId) {
        resolve({geneName: geneName, ensemblGeneId: ensemblGeneId});
      } else {
        let url = self.ENSEMBL_GENE_URL
        url = url.replace(/GENESYMBOL/g, geneName );
        $.ajax( url )
          .done(function(data) {
            if (data && Array.isArray(data)) {
              let ensemblIds = []
              data.forEach(function(entry) {
                if (ensemblGeneId == null && entry.type == "gene" && entry.id.startsWith("ENSG")) {
                  ensemblIds.push(entry.id);
                }
              })
              let lookupPromises = []
              let matchingEnsemblGeneId = null
              ensemblIds.forEach(function(id) {
                let p = self._promiseLookupEnsemblGene(id, geneName)
                .then(function(data) {
                  if (data && data.geneName == geneName) {
                    matchingEnsemblGeneId = data.ensembleGeneId
                  }
                })
                lookupPromises.push(p)
              })
              Promise.all(lookupPromises).then(function() {
                if (matchingEnsemblGeneId) {
                  self.geneToEnsemblId[geneName] = matchingEnsemblGeneId;
                  resolve({geneName: geneName, ensemblGeneId: matchingEnsemblGeneId});
                } else {
                  let msg = "Unable to find ensembl gene id that matches gene name " + geneName;
                  console.log(msg);
                  reject(msg );
                }
              })
              .catch(function(error) {
                reject(error)
              })
            }
          })
          .fail(function(error) {
              let msg = "Unable to get ensembl gene id " + url;
              console.log(msg);
              console.log(error)
              reject(msg + '. Error: ' + error);
          })

      }
    })
  }


  _promiseLookupEnsemblGene(id, geneName) {
    let self = this;
    return new Promise(function(resolve, reject) {
      let theGeneName = geneName;
      let ensemblGeneId = id
      let url = self.ENSEMBL_LOOKUP_BY_ID
      url = url.replace(/ENSEMBL-GENE-ID/g, id );
      $.ajax( url )
      .done(function(data) {
        if (data && Array.isArray(data)) {
          let matchedEntry = null;
          data.forEach(function(entry) {
            if (entry.dbname ==  'EntrezGene') {
              matchedEntry = entry;
            }
          })
          if (matchedEntry) {
            resolve({'ensembleGeneId': ensemblGeneId, 'geneName': matchedEntry.display_id })
          } else {
            resolve(null)
          }
        } else {
          let msg  = "No data returned from _promiseLookupEnsemblGene " + url
          console.log(msg);
          console.log(error.responseJSON.error)
          reject("No data returned from ENSEMBL gene lookup for gene <pre>" + theGeneName + "</pre>. "  + error.responseJSON.error);
        }
      })
      .fail(function(error) {
            let msg = "Unable to get lookup by ensembl gene id " + url;
            console.log(msg);
            console.log(error.responseJSON.error)
            reject("An error occurred from ENSEMBL gene lookup for gene <pre>" + theGeneName + "</pre>. "  + error.responseJSON.error);
      })
    })
  }



  promiseGetCachedGeneObject(geneName, resolveOnError=false) {
    var me = this;
    return new Promise( function(resolve, reject) {
      let theGeneName = geneName;
      var theGeneObject = me.geneObjects[theGeneName];
      if (theGeneObject) {
        resolve(theGeneObject);
      } else {
        me.promiseGetGeneObject(theGeneName).then(function(geneObject) {
          resolve(geneObject);
        })
        .catch(function(error) {
          if (resolveOnError) {
            resolve({notFound: theGeneName});
          } else {
            reject(error);
          }
        });
      }

    });
  }


  promiseGetGeneObject(geneName) {
    var me = this;
    return new Promise(function(resolve, reject) {

      var url = me.globalApp.geneInfoServer + geneName;

      // If current build not specified, default to GRCh37
      var buildName = me.genomeBuildHelper.getCurrentBuildName() ? me.genomeBuildHelper.getCurrentBuildName() : "GRCh37";
      $('#build-link').text(buildName);

      var defaultGeneSource = me.geneSource ? me.geneSource : 'gencode';
      let knownGene = me.getKnownGene(geneName);
      let theGeneSource = null;
      if (knownGene && knownGene[defaultGeneSource]) {
        theGeneSource = defaultGeneSource
      } else if (knownGene && knownGene.refseq) {
        theGeneSource = 'refseq';
        let msg = "No Gencode transcripts for " + geneName + ". Using Refseq transcripts instead.";
        me.dispatch.alertIssued( "warning", msg, geneName)
      } else if (knownGene && knownGene.gencode) {
        let msg = "No Refseq transcripts for " + geneName + ". Using Gencode transcripts instead.";
        me.dispatch.alertIssued( "warning", msg, geneName)
        theGeneSource = 'gencode';
      }

      if (theGeneSource) {
        url += "?source="  + theGeneSource;
        url += "&species=" + me.genomeBuildHelper.getCurrentSpeciesLatinName();
        url += "&build="   + buildName;


        fetch(url).then(r => r.json())
        .then((response) => {
          if (response.length > 0 && response[0].hasOwnProperty('gene_name')) {
            var theGeneObject = response[0];
            //me.addUnionedTranscript(theGeneObject)

            // Create an array of exons by filtering the features. Number the exons
            // according to the strand (direction)
            me.determineExons(theGeneObject)
            me.geneObjects[theGeneObject.gene_name] = theGeneObject;
            resolve(theGeneObject);
          } else {
            let msg = "Bypassing gene. No " + theGeneSource + " transcripts for gene " + geneName + ".";
            console.log(msg);
            reject({'message': msg, 'gene': geneName, 'alertType': 'error'});
          }
        })
        .catch((errorThrown) => {
          console.log("An error occurred when getting transcripts for gene <pre>" +  geneName + "</pre>.");
          console.log( "Error: " + errorThrown );
          let msg = "Error " + errorThrown + " occurred when attempting to get transcripts for gene " + geneName;
          reject({'message': msg, 'gene': geneName});
        });

      } else {
        let msg = ""
        if (knownGene) {
          msg = "No Refseq or Gencode transcripts for " + geneName + ".";

        } else {
          msg = "Unknown gene " + geneName;
        }

        reject({'message': msg, 'gene': geneName});
      }



    });
  }

  /*
   * Capture the exons for a gene transcript. For the user interface,
   * this is a convenience function that represents each rectangle on the
   * transcript diagram, which will be a UTR or a CDS for protein coding
   * genes. But for non-protein coding genes, just filter by feature type
   * 'exon'.
   * This function also numbers the exons and sorts them accordingly. The
   * strand determines which is the first exon.
   */
  determineExons(gene) {
    let self = this;
    gene.transcripts.forEach(function(transcript) {

      // Exons are what we use the number the features. Each exon is assigned
      // a number sequentially. For forward strand, we number exons from
      // first to last exon; For reverse strand, we number from last to
      // first exon.
      let exons = transcript.features.filter(function(feature) {
        return feature.feature_type.toLowerCase() == 'exon';
      })
      .sort(function(a,b) {
        if (gene.strand == "+") {
          return a.start - b.start;
        } else {
          return (a.start - b.start) * -1;
        }
      })

      // These are the features (UTRs and CDSs for protein coding transcripts,
      // EXONs for non-protein coding transcripts) that we treat as exons, that we
      // will draw on the trascript diagram
      let exonicFeatures  = transcript.features.filter(function(feature) {
        if ( transcript.transcript_type == 'protein_coding'
            || transcript.transcript_type == 'UNIONED'
            || feature.transcript_type == 'mRNA'
            || feature.transcript_type == 'transcript'
            || feature.transcript_type == 'primary_transcript') {
          return feature.feature_type.toLowerCase() == 'utr' || feature.feature_type.toLowerCase() == 'cds';
        } else {
          return feature.feature_type.toLowerCase() == 'exon';
        }
      })
      .sort(function(a,b) {
        if (gene.strand == "+") {
          return a.start - b.start;
        } else {
          return (a.start - b.start) * -1;
        }
      })

      // Assign the exon number sequentially
      let count = 1;
      exons.forEach(function(exon) {
        exon.number = count++;
        exon.name = 'Exon ' + exon.number + ' (of ' + exons.length + ')'
      })

      let getEncapsulatingExon = function(feature) {
        let matched = exons.filter(function(exon) {
          return exon.start <= feature.start && exon.end >= feature.end;
        })
        if (matched.length > 0) {
          return matched[0];
        } else {
          return null;
        }
      }

       // Number the UTR and CDS according to the encapsulating EXON.
      exonicFeatures.forEach(function(feature) {
        if (!feature.hasOwnProperty("number")) {
          let theExon = getEncapsulatingExon(feature, exons)
          if (theExon) {
            feature.number = theExon.number;
          } else {
            console.log("Unable to find encapsulating exon in gene " +
              gene.gene_name + " for feature " +
              feature.feature_type + " " +
              feature.start + "-" + feature.end +
              ". Feature will not numbered.")
          }

        }
      })


      transcript.exons = exonicFeatures;
      transcript.exonsOnly = exons;

    })
    return gene;
  }



  promiseGetOtherGeneObjectsInRegion(geneNameToExclude, chr, start, end, transcriptsMode) {
    var me = this;
    return new Promise(function(resolve, reject) {
      var url = me.globalApp.geneInfoServer + "api/region/" + chr + ":" + start + "-" + end;

      // If current build not specified, default to GRCh37
      var buildName = me.genomeBuildHelper.getCurrentBuildName() ? me.genomeBuildHelper.getCurrentBuildName() : "GRCh37";
      var theGeneSource = me.geneSource ? me.geneSource : 'gencode';

      url += "?source="  + theGeneSource;
      url += "&species=" + me.genomeBuildHelper.getCurrentSpeciesLatinName();
      url += "&build="   + buildName;

      fetch(url).then(r => r.json())
      .then((response) => {
        if (response.length > 0 && response[0].hasOwnProperty('gene_name')) {
          var geneObjects = response.map(function(geneObject) {
            // Create an array of all exons from the features array. Number the
            // exons and order them accordingly.
            return me.determineExons(geneObject)
          }).filter(function(geneObject) {
            let transcriptsInRegion = me.getTranscriptsInRegion(geneObject, start, end, transcriptsMode)
            return geneObject.gene_name != geneNameToExclude && transcriptsInRegion.length > 0;
          })
          resolve(geneObjects);
        }
      })
      .catch((errorThrown) => {
        console.log("An error occurred when getting genes in region for <pre>" +
                    chr + ":" + start + "-" + end + "</pre>.");
        console.log( "Error: " + errorThrown );
        let msg = "Error " + errorThrown + " occurred when getting genes in region " +
                   chr + ":" + start + "-" + end;
        reject({'message': msg});
      });
    });
  }

  getTranscriptsInRegion(geneObject, start, end, otherTranscriptsMode) {
    let self = this;
    let theTranscripts = []
    if (otherTranscriptsMode == 'mane') {
      geneObject.transcripts.forEach(function(transcript) {
      if (transcript.is_mane_select) {
          transcript.isSelected = true;
          theTranscripts.push(transcript);
        }
      })
      if (theTranscripts.length == 0) {
        let canonicalTranscript = self.getCanonicalTranscript(geneObject);
        if (canonicalTranscript) {
          canonicalTranscript.isSelected = true;
          theTranscripts.push(canonicalTranscript)
        }
      }
    } else {
      geneObject.transcripts.forEach(function(transcript) {
        theTranscripts.push(transcript)
      })
    }
    return theTranscripts.filter(function(transcript) {
      return transcript.start >= start || transcript.end <= end;
    })
  }

  promiseGetGeneForVariant(variant) {
    var me = this;
    return new Promise(function(resolve, reject) {

      var url = me.globalApp.geneInfoServer + 'api/region/' + variant.chrom + ":" + variant.start + "-" + variant.start;

      // If current build not specified, default to GRCh37
      var buildName = me.genomeBuildHelper.getCurrentBuildName() ? me.genomeBuildHelper.getCurrentBuildName() : "GRCh37";


      var defaultGeneSource = me.geneSource ? me.geneSource : 'gencode';


      if (defaultGeneSource) {
        url += "?source="  + defaultGeneSource;
        url += "&species=" + me.genomeBuildHelper.getCurrentSpeciesLatinName();
        url += "&build="   + buildName;
        url += "&bound=inner"


        $.ajax({
          url: url,
          jsonp: "callback",
          type: "GET",
          dataType: "json",
          success: function( response ) {
            if (response.length > 0 && response[0].hasOwnProperty('gene_name')) {
              var theGeneObject = response[0];
              me.geneObjects[theGeneObject.gene_name] = theGeneObject;
              resolve({'gene': theGeneObject, 'variant': variant});
            } else {
              let msg = "Gene model for region " + variant.chrom + ":" + variant.start + " not found.  Empty results returned from " + url;
              console.log(msg);
              reject(msg);
            }
          },
          error: function( xhr, status, errorThrown ) {

            console.log("Gene model for region " + variant.chrom + ":" + variant.start + " not found.  Error occurred.");
            console.log( "Error: " + errorThrown );
            console.log( "Status: " + status );
            console.log( xhr );
            reject("Error " + errorThrown + " occurred when attempting to get gene for region " +  variant.chrom + ":" + variant.start);

          }
        });

      } else {
        reject("No known gene source");
      }



    });
  }

  searchPhenolyzerGenes(phenotypeTerm, statusCallback) {
    var me = this;

    var url = me.phenolyzerServer + '?term=' + phenotypeTerm;
    var status = null;

    $.ajax({
      url: url,
      type: "GET",
      dataType: "json",
      success: function( data ) {
      if (data == "") {
      } else if (data.record == 'queued') {
        if (statusCallback) {
          statusCallback({status:'queued', 'phenotypeTerm': phenotypeTerm});
        }
        setTimeout(function() {
            me.searchPhenolyzerGenes(phenotypeTerm, statusCallback);
          }, 5000);
      } else if (data.record == 'pending') {
        if (statusCallback) {
          statusCallback({status:'running', 'phenotypeTerm': phenotypeTerm});
        }
        setTimeout(function() {
            me.searchPhenolyzerGenes(phenotypeTerm, statusCallback);
          }, 5000);
      } else {
        me.parsePhenolyzerGenes(data.record, me.NUMBER_PHENOLYZER_GENES);
        if (statusCallback) {
          me.setGenePhenotypeHitsFromPhenolyzer(phenotypeTerm, me.phenolyzerGenes);
          statusCallback({status:'done', 'phenotypeTerm': phenotypeTerm, 'genes': me.phenolyzerGenes});
        }

      }

      },
      fail: function() {
        alert("An error occurred in Phenolyzer iobio services. " + thrownError);
        if (statusCallback) {
          me.setGenePhenotypeHitsFromPhenolyzer(phenotypeTerm, null);
          statusCallback({status:'error', error: thrownError})
        }
      }
    });

  }

  parsePhenolyzerGenes(data, numberPhenolyzerGenes) {
    var me = this;
    var count = 0;
    me.phenolyzerGenes = [];
    data.split("\n").forEach( function(rec) {
      var fields = rec.split("\t");
      if (fields.length > 2 && fields[1]!=="Gene") {
        var geneName               = fields[1];
        if (count < numberPhenolyzerGenes) {
          var rank                 = fields[0];
          var score                = fields[3];
          var haploInsuffScore     = fields[5];
          var geneIntoleranceScore = fields[6];
          var selected             = count < me.phenolyzerTopGenesToKeep ? true : false;
          me.phenolyzerGenes.push({rank: rank, geneName: geneName, score: score, haploInsuffScore: haploInsuffScore, geneIntoleranceScore: geneIntoleranceScore, selected: selected});
        }
        count++;

      }
    });

  }

  promiseGetLinks(geneName) {
    let me = this;

    return new Promise(function(resolve, reject) {
      let links = [];


      var geneCoord = null;
      var geneObject = me.geneObjects[geneName];
      if (geneObject) {
        geneCoord = geneObject.chr + ":" + geneObject.start + "-" + geneObject.end;
      }
      let ensemblGeneId = null

      let populateLinks =  function() {
        var buildAliasUCSC = me.genomeBuildHelper.getBuildAlias('UCSC');
        var geneUID = null;
        var ncbiInfo = me.geneNCBISummaries[geneName];
        if (ncbiInfo) {
          geneUID = ncbiInfo.uid;
        }
        for (var linkName in me.linkTemplates) {
          var theLink = $.extend({}, me.linkTemplates[linkName]);
          theLink.name = linkName;
          let resolved = false;
          if (geneUID && theLink.url.indexOf('GENEUID') >= 0) {
            theLink.url = theLink.url.replace(/GENEUID/g, geneUID );
            resolved = true;
          }
          if (geneObject && theLink.url.indexOf('GENESYMBOL') >= 0) {
            theLink.url = theLink.url.replace(/GENESYMBOL/g, geneName);
            resolved = true;
          }
          if (geneCoord && theLink.url.indexOf('GENECOORD') >= 0) {
            theLink.url = theLink.url.replace(/GENECOORD/g, geneCoord);
            resolved = true;
          }
          if (buildAliasUCSC && theLink.url.indexOf('GENOMEBUILD-ALIAS-UCSC') >= 0) {
            theLink.url = theLink.url.replace(/GENOMEBUILD-ALIAS-UCSC/g, buildAliasUCSC);
            resolved = true;
          }
          if (ensemblGeneId && theLink.url.indexOf('ENSEMBL-GENE-ID') >= 0) {
            theLink.url = theLink.url.replace(/ENSEMBL-GENE-ID/g, ensemblGeneId);
            resolved = true;
          }
          if (resolved) {
            links.push(theLink)
          }
        }
      }


      me.promiseGetGeneEnsemblId(geneName)
      .then(function(data) {
        ensemblGeneId = data.ensemblGeneId
        return me.promiseGetNCBIGeneSummary(geneName)
      })
      .then(function() {
        populateLinks()
        resolve(links)
      })
      .catch(function(error) {
        me.dispatch.alertIssued('info', error, geneName)
        populateLinks()
        resolve(links)

      })

    })
  }

  getVariantLinks(geneName, variant) {

    let me = this;
    let variantLinks = [];

    var variantCoordUCSC = null;
    var variantCoordVarSome = null;
    var variantCoordGNomAD
    var geneObject = me.geneObjects[geneName];

    var buildAliasUCSC = me.genomeBuildHelper.getBuildAlias('UCSC');

    if (geneObject) {
      variantCoordUCSC    = geneObject.chr + ":" + variant.start + "-" + variant.end;

      if (variant.alt.length > variant.ref.length && variant.ref.length == 1) {
        // ins
        variantCoordVarSome = geneObject.chr + "-" + (variant.start+1) + "-"  + '-' + variant.alt.substr(1);
      } else if (variant.ref.length > variant.alt.length && variant.alt.length == 1) {
        // del
        variantCoordVarSome = geneObject.chr + "-" + (variant.start+1) + "-"  + variant.ref.substr(1) ;
      } else if (variant.ref.length == variant.alt.length) {
        // snp
        variantCoordVarSome = geneObject.chr + "-" + variant.start + "-" + variant.ref + '-' + variant.alt;
      } else {
        // complex - just show varsome = at given loci
        variantCoordVarSome = geneObject.chr + "-" + variant.start
      }

      variantCoordGNomAD  = me.globalApp.utility.stripRefName(geneObject.chr) + "-" + variant.start + "-" + variant.ref + '-' + variant.alt;
      if (me.genomeBuildHelper.getCurrentBuildName() == 'GRCh38') {
        variantCoordGNomAD += "?dataset=gnomad_r3"
      };

    }

    var info = me.globalApp.utility.formatDisplay(variant, me.translator, false);


    for (var linkName in me.variantLinkTemplates) {
      var theLink = $.extend({}, me.variantLinkTemplates[linkName]);
      theLink.name = linkName;

      if (variantCoordGNomAD) {
        theLink.url = theLink.url.replace(/VARIANTCOORD-GNOMAD/g, variantCoordGNomAD);
      }
      if (variantCoordUCSC) {
        theLink.url = theLink.url.replace(/VARIANTCOORD-UCSC/g, variantCoordUCSC);
      }
      if (variantCoordVarSome) {
        theLink.url = theLink.url.replace(/VARIANTCOORD-VARSOME/g, variantCoordVarSome);
      }
      if (buildAliasUCSC) {
        theLink.url = theLink.url.replace(/GENOMEBUILD-ALIAS-UCSC/g, buildAliasUCSC);
      }
      if (info && info.rsId &&  info.rsId.length > 0) {
        theLink.url = theLink.url.replace(/VARIANT-RSID/g, info.rsId);
      }
      if (variant.clinvarUid) {
        theLink.url = theLink.url.replace(/VARIANT-CLINVAR-UID/g, variant.clinvarUid);
      }
      var keep = false;

      if (linkName == 'gnomad') {
        if (variant.vepAf && variant.vepAf.gnomAD && variant.vepAf.gnomAD.AF && variant.vepAf.gnomAD.AF != ".") {
          keep = true;
        }
      } else if (linkName == 'dbsnp') {
        if (info && info.rsId &&  info.rsId.length > 0) {
          keep = true;
        }
      } else if (linkName == 'clinvar') {
        if (variant.clinvarUid && variant.clinvarUid.length > 0) {
          keep = true;
        }
      } else {
        keep = true;
      }
      if (keep) {
        variantLinks.push(theLink);
      }
    }

    return variantLinks;

  }


  isKnownGene(geneName) {
    return this.allKnownGeneNames[geneName] != null || this.allKnownGeneNames[geneName.toUpperCase()] != null;
  }

  promiseIsValidGene(geneName) {
    let self = this;
    return new Promise(function(resolve, reject) {
      if (self.isKnownGene(geneName)) {
        self.promiseGetGeneObject(geneName)
        .then(function() {
          resolve(true);
        })
        .catch(function(error) {
          resolve(false);
        })
      } else {
        resolve(false);
      }
    })
  }

  getKnownGene(geneName) {
    if (this.allKnownGeneNames[geneName]) {
      return this.allKnownGeneNames[geneName];
    } else {
      return this.allKnownGeneNames[geneName.toUpperCase()]
    };
  }


  adjustGeneRegion(geneObject) {
    let me = this;
    if (geneObject.startOrig == null) {
      geneObject.startOrig = geneObject.start;
    }
    if (geneObject.endOrig == null) {
      geneObject.endOrig = geneObject.end;
    }
    // Open up gene region to include upstream and downstream region;
    geneObject.start = geneObject.startOrig < me.geneRegionBuffer ? 0 : geneObject.startOrig - me.geneRegionBuffer;
    // TODO: Don't go past length of reference
    geneObject.end   = geneObject.endOrig + me.geneRegionBuffer;

  }

  getLatestGeneTranscript(geneName) {
    return this.geneToLatestTranscript[geneName];
  }

  setLatestGeneTranscript(geneName, transcript) {
    this.geneToLatestTranscript[geneName] = transcript;
  }

  sortGenes(sortBy) {
    var me = this;

    me.sortedGeneNames = null;


    if (sortBy.indexOf("gene name") >= 0) {
      me.sortedGeneNames = me.geneNames.slice().sort();
    } else if (sortBy.indexOf("harmful variant") >= 0 || sortBy.indexOf("danger summary") >= 0) {
      me.sortedGeneNames = me.geneNames.slice().sort( function(a,b) {
        return me.compareDangerSummary(a,b);
      });
    } else if (sortBy.indexOf("coverage") >= 0) {
      me.sortedGeneNames = me.geneNames.slice().sort( function(a,b) {
        return me.compareDangerSummaryByLowCoverage(a,b);
      });
    } else if (sortBy.indexOf("original") >= 0) {
      me.sortedGeneNames = me.geneNames.slice();
    }

  }

  compareDangerSummary(geneName1, geneName2) {
    var me = this;

    var danger1 = me.geneDangerSummaries[geneName1];
    var danger2 = me.geneDangerSummaries[geneName2];

    var value = me.compareDangerSummaryObjects(danger1, danger2);
    if (value == 0) {
      if (geneName1 < geneName2) {
        value = -1;
      } else if (geneName2 < geneName1) {
        value = 1;
      }
    }
    return value;
  }

  compareDangerSummaryObjects(danger1, danger2) {
    var me = this;
    if (danger1 == null && danger2 == null) {
      return 0;
    } else if (danger2 == null) {
      return -1;
    } else if (danger1 == null) {
      return 1;
    }

    var dangers = [danger1, danger2];


    // clinvar badges
    if (danger1.badges.pathogenic && danger2.badges.pathogenic && danger1.badges.pathogenic.length !== danger2.badges.pathogenic.length) {
      return danger2.badges.pathogenic.length -  danger1.badges.pathogenic.length;
    }

    // inheritance badges
    if (danger1.badges.recessive && danger2.badges.recessive && danger1.badges.recessive.length !== danger2.badges.recessive.length) {
      return danger2.badges.recessive.length -  danger1.badges.recessive.length;
    }
    if (danger1.badges.denovo && danger2.badges.denovo && danger1.badges.denovo.length !== danger2.badges.denovo.length) {
      return danger2.badges.denovo.length -  danger1.badges.denovo.length;
    }

    // high or moderate badge
    if (danger1.badges.high && danger2.badges.high) {
      if (danger1.badges.high.length !== danger2.badges.high.length) {
        return danger2.badges.high.length -  danger1.badges.high.length;
      }
    }


    // lowest clinvar value = highest relevance
    var clinvarValues = [9999, 9999];
    dangers.forEach(function(danger, index) {
      if (danger.CLINVAR) {
        for (var key in danger.CLINVAR) {
          var showBadge = me.translator.clinvarMap[key].badge;
          if (showBadge) {
            clinvarValues[index] = danger.CLINVAR[key].value;
          }
        }
      }
    });
    if (clinvarValues[0] !== clinvarValues[1]) {
      return clinvarValues[0] - clinvarValues[1];
    }

    // lowest impact value = highest relevance
    var impactValues = [9999, 9999];
    dangers.forEach(function(danger, index) {
      if (danger.IMPACT) {
        for (var key in danger.IMPACT) {
          impactValues[index] = me.translator.impactMap[key].value;
        }
      }
    });
    if (impactValues[0] !== impactValues[1]) {
      return impactValues[0] - impactValues[1];
    }

    /*
    // FIXME: Can't compare allele frequencies because it would be lowest for all variants, not
    // a particular variant

    // lowest allele frequency = highest relevance
    var afValues = [9999,9999];
    dangers.forEach(function(danger, index) {
      if (danger.AF && Object.keys(danger.AF).length > 0) {
        var clazz   = Object.keys(danger.AF)[0];
        var afValue  = danger.AF[clazz].value;
        afValues[index] = afValue;
      }
    });
    if (afValues[0] !== afValues[1]) {
      return afValues[0] - afValues[1];
    }
    */

    return 0;
  }

  compareDangerSummaryOld(geneName1, geneName2) {
    var me = this;

    var danger1 = me.geneDangerSummaries[geneName1];
    var danger2 = me.geneDangerSummaries[geneName2];

    if (danger1 == null && danger2 == null) {
      return 0;
    } else if (danger2 == null) {
      return -1;
    } else if (danger1 == null) {
      return 1;
    }

    var dangers = [danger1, danger2];


    // lowests (non-zero) harmful variant level  = highest relevance
    var harmfulVariantValues = [9999, 9999];
    dangers.forEach(function(danger, index) {
      if (danger.harmfulVariantsLevel) {
        harmfulVariantValues[index] = danger.harmfulVariantsLevel;
      }
    });
    if (harmfulVariantValues[0] !== harmfulVariantValues[1]) {
      return harmfulVariantValues[0] - harmfulVariantValues[1];
    }

    // lowest clinvar value = highest relevance
    var clinvarValues = [9999, 9999];
    dangers.forEach(function(danger, index) {
      if (danger.CLINVAR) {
        for (var key in danger.CLINVAR) {
          var showBadge = me.translator.clinvarMap[key].badge;
          if (showBadge) {
            clinvarValues[index] = danger.CLINVAR[key].value;
          }
        }
      }
    });
    if (clinvarValues[0] !== clinvarValues[1]) {
      return clinvarValues[0] - clinvarValues[1];
    }

    // sift
    var siftValues = [9999, 9999];
    dangers.forEach(function(danger, index) {
      if (danger.SIFT) {
        for (var key in danger.SIFT) {
          var siftClass = Object.keys(danger.SIFT[key])[0];
          var showBadge = me.translator.siftMap[siftClass].badge;
          if (showBadge) {
            siftValues[index] = me.translator.siftMap[siftClass].value;
          }
        }
      }
    });
    if (siftValues[0] !== siftValues[1]) {
      return siftValues[0] - siftValues[1];
    }

    // polyphen
    var polyphenValues = [9999, 9999];
    dangers.forEach(function(danger, index) {
      if (danger.POLYPHEN) {
        for (var key in danger.POLYPHEN) {
          var polyphenClass = Object.keys(danger.POLYPHEN[key])[0];
          var showBadge = me.translator.polyphenMap[polyphenClass].badge;
          if (showBadge) {
            polyphenValues[index] = me.translator.polyphenMap[polyphenClass].value;
          }
        }
      }
    });
    if (polyphenValues[0] !== polyphenValues[1]) {
      return polyphenValues[0] - polyphenValues[1];
    }

    // lowest impact value = highest relevance
    var impactValues = [9999, 9999];
    dangers.forEach(function(danger, index) {
      if (danger.IMPACT) {
        for (var key in danger.IMPACT) {
          impactValues[index] = me.translator.impactMap[key].value;
        }
      }
    });
    if (impactValues[0] !== impactValues[1]) {
      return impactValues[0] - impactValues[1];
    }

    // lowest allele frequency = highest relevance
    var afValues = [9999,9999];
    dangers.forEach(function(danger, index) {
      if (danger.AF && Object.keys(danger.AF).length > 0) {
        var clazz   = Object.keys(danger.AF)[0];
        var afValue  = danger.AF[clazz].value;
        afValues[index] = afValue;
      }
    });
    if (afValues[0] !== afValues[1]) {
      return afValues[0] - afValues[1];
    }



    if (geneName1 < geneName2) {
      return -1;
    } else if (geneName2 < geneName1) {
      return 1;
    }
    return 0;
  }


  compareDangerSummaryByLowCoverage(geneName1, geneName2) {
    var me = this;

    var danger1 = me.geneDangerSummaries[geneName1];
    var danger2 = me.geneDangerSummaries[geneName2];


    if (danger1 == null && danger2 == null) {
      return 0;
    } else if (danger2 == null) {
      return -1;
    } else if (danger1 == null) {
      return 1;
    }

    geneCoverageProblem1 = danger1.geneCoverageProblem ? danger1.geneCoverageProblem : false;
    geneCoverageProblem2 = danger2.geneCoverageProblem ? danger2.geneCoverageProblem : false;


    if (geneCoverageProblem1 == geneCoverageProblem2) {
      if (geneName1 < geneName2) {
        return -1;
      } else if (geneName2 < geneName1) {
        return 1;
      } else {
        return 0;
      }
    } else if (geneCoverageProblem1) {
      return -1;
    } else if (geneCoverageProblem2) {
      return 1;
    }

  }

  setSourceForGenes(genes, source) {
    let self = this;
    let sourceIndicatorMap = {
      "imported_gene": 1,
      "phenotype_gene_list": 2
    }
    let sourceMap = {
      "imported_gene": "Variant is a member of an imported set of potentially interesting variants",
      "phenotype_gene_list": "Variant is in a gene associated with the patient's clinical note"
    }
    let sourceGeneTabMap = {
      "imported_gene": "Genes contains an imported potentially interesting variant",
      "phenotype_gene_list": "Gene is associated with the patient's clinical note"
    }
    genes.forEach(gene => {
      if(self.genesAssociatedWithSource[gene] === undefined){
        self.genesAssociatedWithSource[gene] = {
          "source": [sourceMap[source]],
          "sourceIndicator": [sourceIndicatorMap[source]],
          "source_gene_tab": [sourceGeneTabMap[source]],
        }
      }
      else {
        if(!self.genesAssociatedWithSource[gene].source.includes(sourceMap[source])){
          self.genesAssociatedWithSource[gene].source.push(sourceMap[source])
          self.genesAssociatedWithSource[gene].sourceIndicator.push(sourceIndicatorMap[source])
          self.genesAssociatedWithSource[gene].source_gene_tab.push(sourceGeneTabMap[source])
        }
      }
    })
  }

  getSourceForGenes() {
    let self = this;
    return self.genesAssociatedWithSource;
  }

}



export default GeneModel
